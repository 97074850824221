import { OrgCompositeRankingResRelatedRankingResponseRelatedPagedData } from '@on3/api';
import { useSite } from '@on3/ui-lib/index';
import { On3Avatar } from 'components/Image/On3Avatar';
import Link from 'next/link';

import styles from './TopRankings.module.scss';

interface TopRankingsProps {
  rankings: OrgCompositeRankingResRelatedRankingResponseRelatedPagedData;
}

export const TopRankings = ({ rankings }: TopRankingsProps) => {
  const { sportsData = [] } = useSite();
  const { relatedModel, list = [] } = rankings;
  const { sportKey = 1 } = relatedModel?.ranking ?? {};

  const sport = sportsData.find((s) => s.key === sportKey);
  const sportName = sport?.name ?? 'Football';
  const sportSlug = sportName.toLowerCase();

  return (
    <div className={styles.block}>
      <h2>National High School {sportName} Composite Rankings</h2>
      <section className={styles.rankings}>
        {list.map((ranking) => (
          <Link
            className={styles.ranking}
            href={`/high-school/${ranking.organization?.urlSlug}/`}
            key={ranking.organizationKey}
          >
            <div className={styles.rank}>{ranking.compositeOverallRank}</div>
            <div className={styles.team}>
              <figure>
                <On3Avatar
                  altText={`${ranking.organization?.name} ${ranking.organization?.mascot} Logo`}
                  domain={ranking.organization?.defaultAsset?.domain}
                  source={ranking.organization?.defaultAsset?.source}
                  teamAvatar
                />
              </figure>
              <div>
                <span className={styles.name}>
                  {ranking.organization?.name}
                </span>
                {/* TODO: swap back once BE has data */}
                {/* <span>{`${ranking.city?.name}, ${ranking?.state?.abbreviation}`}</span> */}
                <span>{`${ranking?.state?.name}`}</span>
              </div>
            </div>
            <div className={styles.rating}>
              <span>{ranking.compositeScore?.toFixed(3)}</span>
              {/* TODO: swap back once BE has data */}
              {/* <span>{ranking.record}</span> */}
            </div>
          </Link>
        ))}
      </section>
      <div>
        <Link
          className={styles.viewAll}
          href={`/high-school/rankings/${sportSlug}/national/`}
        >
          Complete Rankings
        </Link>
      </div>
    </div>
  );
};
