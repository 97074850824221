import { Circle, Whatshot } from '@mui/icons-material';
import {
  TrendingListItemResponse,
  TrendingListItemResponseTrendingListResponseRelatedPagedData,
  TrendingListItemType,
} from '@on3/api';
import { ServiceLogo } from '@on3/ui-lib/components/ServiceLogo/ServiceLogo';
import Link from 'next/link';
import { useMemo } from 'react';

import styles from './TopHeadlines.module.scss';

const Label = ({ type }: { type: string }) => {
  switch (type) {
    case TrendingListItemType.Trending:
      return (
        <span className={styles.tag} data-type="trending">
          Trending
        </span>
      );
    case TrendingListItemType.New:
      return (
        <span className={styles.tag} data-type="new">
          New
        </span>
      );
    case TrendingListItemType.Hot:
      return (
        <span className={styles.tag} data-type="hot">
          <Whatshot />
          Hot
        </span>
      );
    case TrendingListItemType.Live:
      return (
        <span className={styles.tag} data-type="live">
          <Circle />
          Live
        </span>
      );
    case TrendingListItemType.Breaking:
      return (
        <span className={styles.tag} data-type="breaking">
          Breaking
        </span>
      );
    default:
      return null;
  }
};

export const TopHeadlines = ({
  data,
}: {
  data: TrendingListItemResponseTrendingListResponseRelatedPagedData;
}) => {
  const list = useMemo(() => data.list ?? [], [data]);
  const showLink = useMemo(
    () =>
      (data?.pagination?.limit ?? 0) <
      Math.min(Number(data?.pagination?.count) || 10, 10),
    [data],
  );

  return (
    <section className={styles.block}>
      <h2>
        <Link href="/">
          <ServiceLogo type="on3" />
          {data.relatedModel?.name}
        </Link>
      </h2>
      <ol className={styles.list}>
        {list.map((item: TrendingListItemResponse, index) => (
          <li key={item.key}>
            <Link
              className={styles.listItem}
              href={item.url ?? ''}
              title={item.headline ?? ''}
            >
              <span className={styles.index}>{index + 1}</span>
              <div className={styles.content}>
                <div>
                  <h3>{item.subject}</h3>
                  <p>{item.headline}</p>
                </div>
                <Label type={item.type ?? ''} />
              </div>
            </Link>
          </li>
        ))}
      </ol>

      {showLink && <Link href="/">View All</Link>}
    </section>
  );
};
