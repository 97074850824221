import { SVGProps } from 'react';

const SvgForum = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    {...props}
  >
    <path
      d="M1.4 0h11.2c.77 0 1.393.63 1.393 1.4L14 14l-2.8-2.8H1.4c-.77 0-1.4-.63-1.4-1.4V1.4C0 .63.63 0 1.4 0Zm7.7 6.3h1.4V4.9H9.1v1.4Zm-2.8 0h1.4V4.9H6.3v1.4Zm-2.8 0h1.4V4.9H3.5v1.4Z"
      fill="#6F747B"
    />
  </svg>
);

export default SvgForum;
