import { TeamRankingResponseRelatedTeamRankingResponseRelatedPagedData } from '@on3/api';
import { useSite } from '@on3/ui-lib/index';
import { On3Avatar } from 'components/Image/On3Avatar';
import Link from 'next/link';

import styles from './TopRankings.module.scss';

interface TopRankingsProps {
  rankings: TeamRankingResponseRelatedTeamRankingResponseRelatedPagedData;
}

export const TopRankings = ({ rankings }: TopRankingsProps) => {
  const { sportsData = [] } = useSite();
  const { relatedModel, list = [] } = rankings;
  const { sportKey = 1 } = relatedModel ?? {};

  const sport = sportsData.find((s) => s.key === sportKey);
  const sportName = sport?.name ?? 'Football';
  const sportSlug = sportName.toLowerCase();

  return (
    <div className={styles.block}>
      <h2>College {sportName} Rankings</h2>
      <section className={styles.rankings}>
        {list.map((ranking) => (
          <Link
            className={styles.ranking}
            href={`/college/${ranking.currentOrganization?.slug}/`}
            key={ranking.orgKey}
          >
            <div className={styles.rank}>{ranking.overallRank}</div>
            <div className={styles.team}>
              <figure>
                <On3Avatar
                  altText={`${ranking.currentOrganization?.name} ${ranking.currentOrganization?.mascot} Logo`}
                  domain={ranking.currentOrganization?.defaultAsset?.domain}
                  source={ranking.currentOrganization?.defaultAsset?.source}
                  teamAvatar
                />
              </figure>
              <div>
                <span className={styles.name}>
                  {ranking.currentOrganization?.name}
                </span>
                <span>{`${ranking.currentOrganization?.mascot}`}</span>
              </div>
            </div>
            <div className={styles.rating}>
              <span>{ranking.overallScore?.toFixed(2)}</span>
              <span>{ranking.record}</span>
            </div>
          </Link>
        ))}
      </section>
      <div>
        <Link
          className={styles.viewAll}
          href={`/college/rankings/${sportSlug}/`}
        >
          Complete Rankings
        </Link>
      </div>
    </div>
  );
};
